
.page-body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: space-between;
  height: calc(100vh - 92px);
  background-color: #fff;
  .left {
    height: 100%;
    width: 276px;
    flex: none;
    display: flex;
    flex-flow: column;
    border: 1px solid #f0f0f0;
    background-color: #fff;
    padding: 0;
    .buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex: none;
      .button {
        flex: auto;
      }
    }
    .tree {
      flex: auto;
    }
  }
  .middle {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-between;
    height: var(--main-page-body-height);
    margin: 0;
    flex: auto;
    border: 1px solid #f0f0f0;
    background-color: #fff;
    width: calc(100vw - 850px);
    .video {
      background: #000;
      flex: none;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    .logs {
      flex: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      .title {
        flex: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: #000;
        font-size: 14px;
        font-weight: 800;
        height: 40px;
      }
      // flex: none;
      // height: calc(var(--main-page-body-height) - 400px);
    }
  }
  .right {
    height: 100%;
    width: 300px;
    flex: none;
    border: 1px solid #f0f0f0;
    background-color: #fff;
    color: #000000d9;
    padding: 12px;
    font-size: 14px;
    .header {
      display: flex;
      flex-flow: row nowrap;
      align-content: center;
      line-height: 32px;
      justify-content: space-between;
    }
    .guans {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-evenly;
      align-items: center;
      height: 203px;
      width: 100%;
      .guan-item {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        margin: 0;
        flex: none;
        .guan-svg {
          width: 60px;
          height: 140px;
          text-align: center;
        }
      }
    }
  }
}
